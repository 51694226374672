import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { primaryButtonClass } from '../../constants/classConstants'
import AVATAR_CHILD from '../../assets/avatars/Child1.svg'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { usePatient } from '../../contexts/PatientProvider'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { ONBOARDING_STEP } from '../../types/Patient'

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.yourStory },
}

const YourStory: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { setPatient } = usePatient()
  const navigate = useNavigate()

  const getStartedClick = () => {

    // if any of the fields are in the URL
    if (searchParams.get('firstName')) {
        setPatient({
            firstName: searchParams.get('firstName'),
            lastName: searchParams.get('lastName'),
            birthDate: searchParams.get('birthDate'),
            studentId: searchParams.get('studentId'),
            relationship:
                searchParams.get('forSelf') === 'true'
                    ? {name: 'Myself', key: 'myself'}
                    : null,
        })
        trackMixPanel({
            eventName: MIXPANEL_DATA.eventName,
            properties: {
                ...MIXPANEL_DATA.properties,
                patient: searchParams.get('firstName') + ' ' + searchParams.get('lastName'),
            },
        })
    } else {
        setPatient(null)
        trackMixPanel(MIXPANEL_DATA)
    }

    navigate(
      `/onboarding/${
        searchParams.has('forSelf') ? 'build-profile' : 'care-for'
      }?${new URLSearchParams(searchParams).toString()}`
    )
  }

  return (
    <div className="max-w-lg">
      {/* Texts */}
      <p className="text-center text-2xl font-bold xs:text-lg">
        Tell us your Story
      </p>
      <p className="w-full text-center text-base font-normal xs:text-sm">
        We want to learn your story so we can match you with the care you need!
      </p>

      {/* Vector */}
      <img src={AVATAR_CHILD} alt="self" className="h-32 w-32 self-center" />

      {/* Submit */}
      <button
        type="submit"
        className={`${primaryButtonClass} w-full`}
        onClick={getStartedClick}
      >
        Let's get started!
      </button>

      <SkipAndComeBackLater
        mixPanelData={{
          ...MIXPANEL_DATA,
          properties: {
            ...MIXPANEL_DATA.properties,
            skipped: true,
          },
        }}
      />
    </div>
  )
}

export default YourStory
