import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import { ChevronRightIcon, RefreshIcon } from '@heroicons/react/solid'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { flushSync } from 'react-dom'

import { useAuth } from '../../contexts/AuthProvider'
import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
} from '../../constants/classConstants'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { EMAIL_REGEX } from '../../constants/regex'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { formatPossessiveNoun } from '../../helpers/generic'
import { usePermissionSettingsContext } from '../../contexts/PermissionSettingsContext'
import { useUpdateSeparateLoginPatient } from '../../mutations/permissions/UpdateSeparateLoginPatient'
import { auth } from '../../config/firebase'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import { useGetPatientPacket } from '../../queries/onboarding/GetPatientPacket'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { getSourceFromLocation } from '../../helpers/utils'

interface PermissionForm {
  email: string
  ableToBookAndCancel: boolean
}

const formSchema = () =>
  Yup.object().shape({
    email: Yup.string().optional().matches(EMAIL_REGEX, 'Email is not valid.'),
  })

const CreatePermission: React.FC = () => {
  const { patientId } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { email, setEmail, ableToBookAndCancel, setAbleToBookAndCancel } =
    usePermissionSettingsContext()

  const [loading, setLoading] = useState<boolean>(false)

  const { mutate: callUpdateSeparateLoginProps } =
    useUpdateSeparateLoginPatient()
  const { data: patientPacket, isLoading: isLoadingGetPatientPacket } =
    useGetPatientPacket({
      patientId,
    })

  const patient = user.roster?.find((p) => p.id === patientId)

  const {
    register,
    setError,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PermissionForm>({
    defaultValues: {
      ableToBookAndCancel,
      email,
    },
    mode: 'all',
    resolver: yupResolver(formSchema()),
  })

  const onSubmit = async (data: PermissionForm) => {
    setLoading(true)

    const result = await fetchSignInMethodsForEmail(auth, data.email)

    // check if email already exists
    if (result?.length > 0) {
      setLoading(false)
      return setError('email', {
        message: 'Please enter a valid email address.',
      })
    }

    callUpdateSeparateLoginProps(
      { ableToBookAndCancel: data.ableToBookAndCancel, patient },
      {
        onSettled: () => {
          flushSync(() => {
            setEmail(data.email)
            setAbleToBookAndCancel(data.ableToBookAndCancel)

            trackMixPanel({
              eventName: MIXPANEL_EVENT.ACCOUNT_UPDATED,
              properties: {
                category: 'Separate Login Enabled',
                accountId: user.data.id,
                source: getSourceFromLocation(location),
                patient: patient?.firstName + ' ' + patient?.lastName,
              },
            })

            // no patient packet
            if (!patientPacket?.signed) return navigate(`patient-packet`)

            navigate('consent')
            setLoading(false)
          })
        },
      }
    )
  }

  const isLoading = loading || isLoadingGetPatientPacket

  if (!patientId) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-xl items-center justify-center">
      {/* Header */}
      <div className="flex flex-col gap-6 xs:gap-4">
        <h1 className="text-center text-2xl font-medium xs:text-lg">
          Create Separate Login for {patient.firstName}
        </h1>
        <p className="whitespace-pre-wrap text-center text-base xs:text-sm">
          This will allow {patient.firstName} to access their profile from a
          separate login to ensure privacy when logging in from their own
          devices. <br />
          An email invite will be sent for them to set up a password.
        </p>
      </div>

      {/* Form */}
      <div className="flex w-full max-w-md flex-col gap-6 xs:gap-4">
        {/* Email */}
        <div className="flex flex-col gap-1">
          <label htmlFor="email" className="text-sm font-semibold sm:text-base">
            {formatPossessiveNoun(patient.firstName)} Email
          </label>
          <div className="relative">
            <input
              type="email"
              className={errors.email ? inputErrorClass : inputValidClass}
              placeholder="Email"
              {...register('email')}
            />
            {Boolean(errors.email) && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-status-error"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {Boolean(errors.email) && (
            <p className="text-sm text-status-error">{errors.email?.message}</p>
          )}
        </div>

        {/* Account permissions */}
        <div className="w-full border-t border-components-fields" />
        <div className="flex flex-col items-center">
          <p className="text-base font-semibold xs:text-sm">
            Account Permissions
          </p>
          <p className="mb-4 text-base xs:text-sm">This account can:</p>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-cta-default focus:ring-cta-disabled"
              {...register('ableToBookAndCancel')}
            />
            <p className="text-base xs:text-sm">Book and cancel sessions</p>
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="flex w-full max-w-lg flex-col gap-6 sm:gap-12">
        <button
          type="button"
          className={`${primaryButtonClass} mx-auto w-full`}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isLoading}
        >
          {isLoading ? (
            <>
              Loading
              <RefreshIcon className="loader h-5 w-5" />
            </>
          ) : (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5" />
            </>
          )}
        </button>
        {!isLoading && (
          <SkipAndComeBackLater handleClick={() => navigate(-1)} />
        )}
      </div>
    </div>
  )
}

export default CreatePermission
