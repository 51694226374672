import React, { useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import InsuranceForm from './InsuranceForm'
import type { Insurance, SelectInsuranceModel } from '../../types/Insurance'
import { useAuth } from '../../contexts/AuthProvider'
import { useToastContext } from '../../contexts/ToastContext'
import {
  useCreateInsuranceMembership,
  useCreateUnassociatedMembership,
} from '../../mutations/onboarding/InsuranceMembership'
import { usePatient } from '../../contexts/PatientProvider'
import type { Patient } from '../../types/Patient'
import { ONBOARDING_STEP } from '../../types/Patient'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const InsuranceInformation: React.FC = () => {
  const addToast = useToastContext()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { patient, setPatient } = usePatient()
  const forSelf: boolean = patient?.relationship?.name === 'Myself'
  const selectedInsurance: SelectInsuranceModel = location.state?.insurance
  const defaulValuesForInsuranceForm: Insurance =
    location.state?.defaulValuesForInsuranceForm

  const { isLoading: isLoadingMembership, mutate: callCreateMembership } =
    useCreateInsuranceMembership()
  const {
    isLoading: isLoadingUnassociatedMembership,
    mutate: callCreateUnassociatedMembership,
  } = useCreateUnassociatedMembership()

  // splitting form in only insurance info and then address
  const [split, setSplit] = useState<boolean>(true)
  const [formData, setFormData] = useState<Insurance>(null)

  // submit function for when entering only insurance info
  const halfSubmit = (data: Insurance) => {
    setFormData(data)
    setSplit(false)
  }

  // submit info when we have both insurance and address info
  const onSubmit = async (insurance: Insurance) => {
    if (insurance.otherInsuranceName)
      callCreateUnassociatedMembership(
        {
          patientId: patient.id,
          insurance,
        },
        {
          onError: onSettledError,
          onSuccess: (insuranceId) =>
            onSettledMembership({ ...insurance, id: insuranceId }, true),
        }
      )
    else
      callCreateMembership(
        {
          patientId: patient.id,
          insurance,
        },
        {
          onError: onSettledError,
          onSuccess: (insuranceId) =>
            onSettledMembership({ ...insurance, id: insuranceId }),
        }
      )
  }

  const onSettledError = () => addToast('error', 'Something went wrong.')

  const onSettledMembership = (
    insurance: Insurance,
    unassociatedInsurance = false
  ) => {
    const patientIdx = user.roster.findIndex(
      (p: Patient) => p.id === patient.id
    )

    const newUser = cloneDeep(user)
    newUser.roster[patientIdx] = {
      ...patient,
      insurance,
      unassociatedInsurance,
      onboardingStep: ONBOARDING_STEP.booking,
    }
    flushSync(() => {
      trackMixPanel({
        eventName: MIXPANEL_EVENT.INSURANCE_UPDATED,
        properties: {
          update: 'Add',
          source: 'Onboarding',
          accountId: newUser.data.id,
          patient: patient?.firstName + ' ' + patient?.lastName,
        },
      })
      setUser(newUser)
      setPatient({
        ...patient,
        insurance,
        unassociatedInsurance,
      })
    })

    // onboarding multiple patients, check if there are patients without insurance
    const newPatientDuringOnboarding = newUser.roster.find(
      (p: Patient) =>
        !p.unassociatedInsurance &&
        !p.insurance &&
        p.onboardingStep === ONBOARDING_STEP.insurance &&
        p.id !== patient.id
    )

    if (newPatientDuringOnboarding) {
      flushSync(() => setPatient(newPatientDuringOnboarding))

      navigate('/insurance/use-same', {
        state: {
          insurance,
        },
      })
      return
    }

    const patientsInBooking = newUser.roster.filter(
      (p: Patient) => p?.onboardingStep === ONBOARDING_STEP.booking
    )

    // if only IEP patients OR current patient not allowed to book
    if (
      patientsInBooking.every((p: Patient) => p?.isIepOnly) ||
      !patient.allowedToBook
    ) {
      navigate('/dashboard')
    } else {
      // default behaviour
      navigate(
        `/select-service-line?multipleOnboarding=${
          patientsInBooking?.length > 1
        }`
      )
    }
  }

  if (!selectedInsurance && !defaulValuesForInsuranceForm)
    return <Navigate to="/dashboard" />

  return (
    <div className="max-w-lg items-center">
      <div className="flex flex-col items-center justify-center gap-6 text-center">
        <p className="text-2xl font-bold text-text-primary xs:text-lg">
          {split
            ? 'Do you have health insurance?'
            : "What is the policy holder's physical address?"}
        </p>
        {split && (
          <p className="text-base font-semibold text-text-primary xs:text-sm xs:font-normal">
            {forSelf ? (
              'Please enter your insurance information'
            ) : (
              <>
                Please enter insurance information for{' '}
                <span className="text-cta-default">{patient.firstName}</span>
              </>
            )}
          </p>
        )}
      </div>

      {/* Form */}
      <InsuranceForm
        defaultValues={
          defaulValuesForInsuranceForm || {
            insurance: selectedInsurance,
            ...formData,
          }
        }
        onSubmit={split ? halfSubmit : onSubmit}
        split={split}
        preSplit={!split}
        editableInsurance={false}
        formButtons={
          <div className="flex w-full gap-2 sm:gap-4">
            <button
              className={`w-1/2 ${tertiaryButtonClass}`}
              disabled={isLoadingMembership || isLoadingUnassociatedMembership}
              onClick={() => {
                if (split) navigate('/insurance')
                else setSplit(true)
              }}
              type="button"
            >
              <ChevronLeftIcon className="h-5 w-5" />
              Back
            </button>
            <button
              className={`w-1/2 ${primaryButtonClass}`}
              disabled={isLoadingMembership || isLoadingUnassociatedMembership}
            >
              {isLoadingMembership || isLoadingUnassociatedMembership ? (
                <>
                  <RefreshIcon
                    className="loader h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  Loading
                </>
              ) : (
                <>
                  Next
                  <ChevronRightIcon className="h-5 w-5 text-white" />
                </>
              )}
            </button>
          </div>
        }
      />
    </div>
  )
}

export default InsuranceInformation
