import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { RefreshIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth'
import { flushSync } from 'react-dom'

import AppLogo from '../../components/AppLogo'
import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
} from '../../constants/classConstants'
import { auth } from '../../config/firebase'
import { useCreateSeparateLogin } from '../../mutations/permissions/CreateSeparateLogin'
import { useGetUserData } from '../../mutations/user/GetUserData'
import type { User } from '../../types/User'
import { useAuth } from '../../contexts/AuthProvider'
import GlobalLoader from '../../components/GlobalLoader'
import { useActivateAccountHolderAndClaim } from '../../mutations/user/ActivateAccountHolderAndClaim'
import { useVerifySecuredToken } from '../../mutations/permissions/VerifySecuredToken'
import { useCreateNewUser } from '../../mutations/user/CreateNewUser'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { EXTERNAL_LINKS } from '../../constants/values'
import { getPatientType } from '../../helpers/utils'
import { useGetClientInfo } from '../../queries/onboarding/GetClientInfo'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter valid first name').trim(),
  lastName: Yup.string().required('Please enter valid last name').trim(),
  password: Yup.string()
    .required('Please enter a password.')
    .min(8, 'Please enter a password, must have 8 characters.')
    .trim(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Please make sure they match.'
  ),
  acceptTerms: Yup.bool().oneOf(
    [true],
    'Please check this box to accept our Terms & Conditions'
  ),
})

interface CreateLoginForm {
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
  acceptTerms: boolean
}

const CreateAccountFromEmail: React.FC = () => {
  const { data: clientInfo } = useGetClientInfo()
  const { setUser } = useAuth()
  const searchParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()

  const email = searchParams.get('email')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const securedToken = searchParams.get('securedToken')
  const isSeparateLogin = searchParams.get('isSeparateLogin') === 'true'

  const [loading, setLoading] = useState<boolean>(false)
  const [verifyingToken, setVerifyingToken] = useState<boolean>(true)
  const [otherError, setOtherError] = useState<boolean>(false)

  const { mutateAsync: callCreateSeparateLogin } = useCreateSeparateLogin()
  const { mutateAsync: callActivateAccountHolderAndClaim } =
    useActivateAccountHolderAndClaim()

  const { mutateAsync: callVerifySecuredToken } = useVerifySecuredToken()
  const { mutate: callGetUserData } = useGetUserData()
  const { mutateAsync: callCreateNewUser } = useCreateNewUser()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateLoginForm>({
    defaultValues: { firstName, lastName },
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (!securedToken) return

    const verifyToken = async () => {
      try {
        await callVerifySecuredToken({ securedToken })
        setVerifyingToken(false)
      } catch (error) {
        if (isSeparateLogin) navigate('/separate-login-invite-expired')
        else navigate('/email-confirmation-failed')
      }
    }

    verifyToken()
  }, [securedToken])

  const onSubmit = async (data: CreateLoginForm) => {
    setLoading(true)
    setOtherError(false)

    try {
      // register with firebase
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        data.password
      )

      // get firebase token
      const initialToken: string = await user.getIdToken()

      // create new user in ruby, new person in java and add claims
      await callCreateNewUser({
        token: initialToken,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: '',
        clientId: clientInfo?.clientId,
      })

      if (isSeparateLogin) await callCreateSeparateLogin()
      else await callActivateAccountHolderAndClaim()

      // get user data
      callGetUserData(null, {
        onError: () => signOut(auth),
        onSuccess: async (resultUserData: User) => {
          const selfPatient = resultUserData.roster[0]
          flushSync(() => {
            setUser(resultUserData)
            navigate(
              `/onboarding/build-profile?patientId=${
                selfPatient.id
              }&signUpFromEmail=true${
                isSeparateLogin ? `&forSelf=${isSeparateLogin}` : ''
              }`,
              { replace: true }
            )
          })
          trackMixPanel({
            eventName: MIXPANEL_EVENT.ACCOUNT_CREATED,
            properties: {
              relationship: isSeparateLogin ? 'Self' : selfPatient?.relationship,
              email: email,
              accountType: getPatientType(selfPatient),
              source: 'Email Invite'
            },
          })
        },
      })
    } catch (error) {
      setOtherError(true)
      setLoading(false)
    }
  }

  if (verifyingToken) return <GlobalLoader />

  if (!email || !securedToken) return <Navigate to="/sign-in" />

  return (
    <div className="flex w-full max-w-xl flex-col items-center justify-center gap-8 xs:gap-6">
      <h1 className="text-center text-2xl font-semibold xs:text-lg">
        Welcome to Huddle Up!
      </h1>

      <AppLogo classes="self-center" />

      <div className="flex flex-col gap-1">
        <p className="text-center text-lg font-semibold xs:text-base">
          Let's finish creating your account for
        </p>
        <p className="text-center text-base font-normal">{email}</p>
      </div>

      {/* Form */}
      <div className="grid w-full grid-cols-2 gap-2">
        {/* First name */}
        <div className="flex flex-col xs:col-span-2">
          <label
            htmlFor="firstName"
            className="block text-base font-semibold xs:text-sm"
          >
            Your first name
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.firstName ? inputErrorClass : inputValidClass}
              placeholder="First"
              disabled={Boolean(firstName)}
              {...register('firstName')}
            />
            {errors.firstName && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.firstName?.message}
          </p>
        </div>

        {/* Last name */}
        <div className="flex flex-col xs:col-span-2">
          <label
            htmlFor="lastName"
            className="block text-base font-semibold xs:text-sm"
          >
            Your last name
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.lastName ? inputErrorClass : inputValidClass}
              placeholder="Last"
              disabled={Boolean(firstName)}
              {...register('lastName')}
            />
            {errors.lastName && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.lastName?.message}
          </p>
        </div>

        {/*  Password */}
        <div className="xs:col-span-2">
          <label
            htmlFor="password"
            className="block text-base font-semibold xs:text-sm"
          >
            Password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={errors.password ? inputErrorClass : inputValidClass}
              placeholder="Password"
              {...register('password')}
            />
            {errors.password && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.password?.message}
          </p>
        </div>

        {/* Confirm Password */}
        <div className="xs:col-span-2">
          <label
            htmlFor="confirm-password"
            className="block text-base font-semibold xs:text-sm"
          >
            Confirm Password
          </label>
          <div className="relative mt-1">
            <input
              type="password"
              className={
                errors.confirmPassword ? inputErrorClass : inputValidClass
              }
              placeholder="Confirm password"
              {...register('confirmPassword')}
            />
            {errors.confirmPassword && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.confirmPassword?.message}
          </p>
        </div>

        {/* Terms */}
        <div className="col-span-2">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                aria-describedby="terms-and-conditions-checkbox"
                type="checkbox"
                {...register('acceptTerms')}
                className="h-4 w-4 rounded border-gray-300 text-cta-default focus:ring-cta-disabled"
              />
            </div>
            <div className="ml-3 text-sm">
              <span id="terms-and-conditions" className="text-base xs:text-sm">
                I agree to Huddle Up's{' '}
                <a
                  href={EXTERNAL_LINKS.TERMS_CONDITIONS}
                  target={'_blank'}
                  className="text-cta-default underline"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
              </span>
              <p className="text-sm text-status-error">
                {errors.acceptTerms?.message}
              </p>
            </div>
          </div>
        </div>

        {otherError && (
          <div className="col-span-2 flex flex-col items-center justify-center text-center text-status-error">
            <p className="text-sm">Hmm, something went wrong.</p>
            <p className="whitespace-pre-wrap text-sm">
              Please contact{' '}
              <a
                href="mailto:support@huddleupcare.com"
                className="font-medium  underline xs:text-sm"
              >
                support@huddleupcare.com
              </a>{' '}
              for more information.
            </p>
          </div>
        )}
      </div>

      {/* Button */}
      <button
        onClick={handleSubmit(onSubmit)}
        className={`${primaryButtonClass} mx-auto w-full`}
        disabled={Boolean(Object.keys(errors)?.length) || !isValid || loading}
      >
        {loading ? (
          <>
            <RefreshIcon
              className="loader h-5 w-5 text-white"
              aria-hidden="true"
            />
            Loading
          </>
        ) : (
          'Save Password'
        )}
      </button>

      {!loading && (
        <Link to="/sign-in" className="text-base font-medium underline">
          This is not me
        </Link>
      )}
    </div>
  )
}

export default CreateAccountFromEmail
