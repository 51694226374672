import React, { useEffect, useState } from 'react'
import { CalendarIcon } from '@heroicons/react/outline'
import { StarIcon } from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'
import { formatInTimeZone } from 'date-fns-tz'
import { flushSync } from 'react-dom'

import {
  inputValidClass,
  primaryButtonClassSmall,
  tertiaryButtonClassSmall,
} from '../../constants/classConstants'
import CenteredModal from '../CenteredModal'
import type { Session } from '../../types/Session'
import { useToastContext } from '../../contexts/ToastContext'
import { useRateSession } from '../../mutations/dashboard/RateSession'
import { useAuth } from '../../contexts/AuthProvider'
import { formatPossessiveNoun } from '../../helpers/generic'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import { LICENSE_CREDENTIAL } from '../../constants/values'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import type { ServiceLine } from '../../types/ServiceLine'
import CrisisMessaging from '../CrisisMessaging'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

export type Star = {
  value: number
  verbiage?: string
}

export const stars: Star[] = [
  { value: 5, verbiage: 'Very good' },
  { value: 4, verbiage: 'Good' },
  { value: 3, verbiage: 'Neutral' },
  { value: 2, verbiage: 'Poor' },
  { value: 1, verbiage: 'Very poor' },
]

const SessionRatingModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  session: Session
}> = ({ open, setOpen, session }) => {
  const { user, setUser } = useAuth()
  const patient = session
    ? user.roster.find((p: Patient) => p.id === session.patientId)
    : null
  const addToast = useToastContext()
  const [feedback, setFeedback] = useState<string>('')
  const [verbiage, setVerbiage] = useState<string>('Please rate')
  const [rating, setRating] = useState<number>(0)
  const { mutate: callRateSession } = useRateSession()

  useEffect(() => {
    if (open || !session) return

    callRateSession(
      {
        feedback,
        rating,
        sessionId: session.id,
      },
      {
        onSettled: () =>
          trackMixPanel({
            eventName: MIXPANEL_EVENT.MODAL_VIEWED,
            properties: {
              name: 'Modal - Rating',
              type: 'Rate Last Therapy Session',
              stars: rating,
              patient: patient?.firstName + ' ' + patient?.lastName,
            },
          }),
      }
    )

    const newUser = cloneDeep(user)
    newUser.roster
      .find((p: Patient) => p.id === session.patientId)
      .conditions.find((c: Condition) => c.id === session.conditionId)
      .carePlans.find((cp: CarePlan) => cp.id === session.carePlanId)
      .sessions.find((s: Session) => s.id === session.id).rating = rating

    // Use setTimeout to move the flushSync call to a micro task
    setTimeout(() => {
      flushSync(() => setUser(newUser))
    }, 0)

    if (rating) addToast('success', 'Thank you for rating your session!')
  }, [open, rating, feedback, session])

  if (!session || !patient || patient?.isIepOnly) return

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        {/* Header */}
        <p className="text-lg font-semibold">
          How would you rate{' '}
          {user.roster.find((p: Patient) => p.id === session.patientId)
            .relationship?.key === 'myself'
            ? 'your'
            : formatPossessiveNoun(session.patientName.split(' ')[0])}{' '}
          last therapy session?
        </p>

        {/* Session info */}
        <div className="mx-auto flex flex-col gap-4">
          {/* Date */}
          <div className="flex items-center gap-2 xs:flex-col xs:items-start xs:gap-0">
            {/* Actual date&time */}
            <div className="flex items-center gap-2 xs:flex-col xs:items-start xs:gap-0">
              <div className="flex items-center gap-2">
                <CalendarIcon className="h-5 w-5 text-text-label" />
                <p className="text-base font-semibold xs:whitespace-pre-wrap xs:text-sm">
                  {formatInTimeZone(
                    session.startTime,
                    patient.timeZone,
                    "EEEE',' MMMM d',' yyyy"
                  )}
                </p>
              </div>
              <p className="font-normal">{`${formatInTimeZone(
                session.startTime,
                patient.timeZone,
                'hh:mm aa'
              )} - ${formatInTimeZone(
                session.endTime,
                patient.timeZone,
                'hh:mm aa'
              )}`}</p>
            </div>
          </div>

          {/* Therapist */}
          <div className="flex flex-col justify-center">
            <div className="flex gap-2">
              <img
                src={
                  SERVICE_LINES_ARRAY.find(
                    (sl: ServiceLine) => sl.displayName === session.type
                  ).avatar
                }
                className="h-5 w-5"
                alt="therapy-svg"
              />{' '}
              <p className="text-base font-semibold xs:text-sm">
                {session.type} <span className="font-normal">with</span>
              </p>
            </div>

            <div className="my-2 ml-8 flex items-center gap-6">
              <img
                src={session.therapist.src}
                className="h-11 w-11 rounded-full border-4 border-components-paleBlue object-cover"
                alt="therapist"
              />
              <div className="text-sm text-text-secondary">
                <p className="font-semibold">
                  {session.therapist.preferredName}
                </p>
                <p className="text-label text-left text-xs font-semibold sm:text-base">
                  {session?.therapist?.licenseCredential ||
                    LICENSE_CREDENTIAL[
                      SERVICE_LINES_ARRAY.find(
                        (line: ServiceLine) =>
                          line.displayName === session.therapist.serviceLine
                      ).serviceType
                    ]}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Rating */}
        <div className="flex flex-col items-center gap-3">
          <div
            className="star-rating flex flex-row-reverse gap-1"
            onMouseLeave={() => !rating && setVerbiage('Please rate')}
          >
            {stars.map((star: Star) => (
              <StarIcon
                key={star.value}
                className={`${
                  rating >= star.value
                    ? 'text-status-warning'
                    : 'text-components-disabled'
                } star h-10 w-10 cursor-pointer`}
                onMouseOver={() => {
                  setVerbiage(star.verbiage)
                }}
                onClick={() => setRating(star.value)}
              />
            ))}
          </div>
          <p className="text-base">{verbiage}</p>
        </div>

        {/* Textarea */}
        <div className="flex flex-col gap-1">
          <label
            htmlFor="feedback"
            className="text-sm font-semibold sm:text-base"
          >
            Anything else you would like to share?
          </label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.currentTarget.value)}
            className={inputValidClass + 'flex-1'}
            placeholder="Enter your feedback here"
          />
        </div>

        <CrisisMessaging noIcon />

        {/* Buttons */}
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-4">
            <button
              type="button"
              className={tertiaryButtonClassSmall}
              onClick={() => setOpen(false)}
            >
              Skip
            </button>
            <button
              className={primaryButtonClassSmall}
              disabled={!rating}
              onClick={() => setOpen(false)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </CenteredModal>
  )
}

export default SessionRatingModal
