import { useLocation } from 'react-router-dom'
import React from 'react'
import cloneDeep from 'lodash.clonedeep'
import { format, differenceInHours } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import { flushSync } from 'react-dom'
import { RefreshIcon } from '@heroicons/react/solid'
import { ExclamationIcon } from '@heroicons/react/outline'

import CenteredModal from '../CenteredModal'
import {
  deleteButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { useToastContext } from '../../contexts/ToastContext'
import { useCancelBooking } from '../../mutations/booking/CancelBooking'
import { useAuth } from '../../contexts/AuthProvider'
import type { Session } from '../../types/Session'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import { usePatient } from '../../contexts/PatientProvider'
import { newDate } from '../../helpers/generic'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { getSourceFromLocation } from '../../helpers/utils'

const CancelBookingModal: React.FC<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  session: Session
}> = ({ open, setOpen, session }) => {
  const location = useLocation()
  const { user, setUser } = useAuth()
  const patient = user.roster.find((p: Patient) => p.id === session.patientId)
  const { setPatient, patient: activeContextPatient } = usePatient()
  const addToast = useToastContext()
  const { mutate: mutateCancelBooking, isLoading: isLoadingCancelBooking } =
    useCancelBooking()

  const cancelBooking = () => {
    const isLateCancel =
      differenceInHours(new Date(session.startTime), new Date()) < 24

    mutateCancelBooking(
      {
        carePlanId: session.carePlanId,
        appointmentId: session.id,
        isLateCancel,
      },
      {
        onSettled: (wasPutSuccessful: boolean) => {
          if (wasPutSuccessful) {
            const newPatient = cloneDeep(patient)
            const currentCarePlan: CarePlan = newPatient?.conditions
              ?.flatMap((c: Condition) => c.carePlans)
              ?.find((cp: CarePlan) => cp.id === session.carePlanId)

            const sessionIdx = currentCarePlan.sessions.findIndex(
              (s: Session) => s.id === session.id
            )

            const currentSession: Session = currentCarePlan.sessions.find(
              (s: Session) => s.id === session.id
            )

            currentCarePlan.sessions[sessionIdx] = {
              ...currentSession,
              status: 'cancelled',
              canceledAt: format(
                newDate(new Date(), newPatient.timeZone),
                `yyyy-MM-dd'T'HH:mm:ss.SSS'${`${
                  getTimezoneOffset(newPatient.timeZone) / 60 / 60000
                }`.replace(/([-+])(\d)/, '$10$2:00')}'`
              ),
            }

            const durationInMinutes: number =
              (new Date(currentSession.endTime).getTime() -
                new Date(currentSession.startTime).getTime()) /
              60000

            // if not late cancel and has a limit of sessions give back points
            if (isLateCancel && Boolean(currentCarePlan.allowedSessions))
              currentCarePlan.remainingSessions +=
                durationInMinutes === 60 ? 2 : 1

            const newUser = cloneDeep(user)
            const patientIdx = newUser.roster.findIndex(
              (p: Patient) => p.id === newPatient.id
            )
            newUser.roster[patientIdx] = newPatient
            flushSync(() => {
              if (Boolean(activeContextPatient)) setPatient(newPatient)
              setUser(newUser)
            })

            trackMixPanel({
              eventName: MIXPANEL_EVENT.SESSION_CANCELLED,
              properties: {
                source: getSourceFromLocation(location),
                therapistId: currentSession.therapist.id,
                sessionId: currentSession.id,
                patient: patient?.firstName + ' ' + patient?.lastName,
              },
            })

            addToast('success', 'Cancelled successfully!')
          } else {
            addToast('error', 'Something went wrong')
          }
          setOpen(false)
        },
      }
    )
  }

  return (
    <CenteredModal setOpen={setOpen} open={open}>
      <div className="flex flex-col gap-6 text-text-primary">
        <div className="flex flex-row gap-4">
          <div className="self-start rounded-full bg-status-error bg-opacity-20 p-2.5">
            <ExclamationIcon className="h-5 w-5 text-status-error" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-semibold">Cancel Booking</p>
            <p>Are you sure you want to cancel this booking?</p>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button
            className={deleteButtonClass}
            onClick={cancelBooking}
            disabled={isLoadingCancelBooking}
          >
            {isLoadingCancelBooking ? (
              <>
                <RefreshIcon className="loader h-5 w-5" /> Loading
              </>
            ) : (
              'Cancel Booking'
            )}
          </button>
          <button
            className={tertiaryButtonClass}
            onClick={() => setOpen(false)}
            disabled={isLoadingCancelBooking}
          >
            Never Mind
          </button>
        </div>
      </div>
    </CenteredModal>
  )
}

export default CancelBookingModal
