import React, { useEffect, useMemo, useState } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import type { ServiceLine } from '../../types/ServiceLine'
import { useAuth } from '../../contexts/AuthProvider'
import Stepper from '../../components/Stepper'
import { useToastContext } from '../../contexts/ToastContext'
import { usePatient } from '../../contexts/PatientProvider'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import { ONBOARDING_STEP } from '../../types/Patient'
import GlobalLoader from '../../components/GlobalLoader'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { states } from '../../constants/values'
import type { State } from '../../types/State'
import { capitalize, enumeratedElementsFromArray } from '../../helpers/generic'
import { useGetClientServiceLines } from '../../queries/onboarding/GetClientServiceLines'
import { useGetProvidersLength } from '../../queries/booking/GetProvidersLength'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import type { Product } from '../../types/User'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.areaFocus },
}

const AreaFocus: React.FC = () => {
  const [searchParams] = useSearchParams()
  const signUpFromEmail = searchParams?.get('signUpFromEmail') == 'true'
  const location = useLocation()
  const skipAddAnotherPatient: boolean = location?.state?.skipAddAnotherPatient
  const { user, setUser } = useAuth()
  const [userSelectedServiceLines, setUserSelectedServiceLines] = useState<
    ServiceLine[]
  >([])
  const [serviceLinesNoTherapistsInState, setServiceLinesNoTherapistsInState] =
    useState<string[]>([])
  const [serviceLinesForDisplay, setServiceLinesForDisplay] = useState<
    ServiceLine[]
  >([])
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { patient, setPatient } = usePatient()
  const patientCarePlans: CarePlan[] = patient?.conditions.find(
    (c: Condition) => !c.isIep
  )?.carePlans
  const stateAbbrev = states.find(
    (s: State) => s?.name === patient?.state
  )?.abbrev
  const { mutate: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const forSelf: boolean = patient?.relationship?.name === 'Myself'
  const { data: clientAvailableSLs, isLoading: isLoadingClientAvailableSLs } =
    useGetClientServiceLines({
      clientId: user.data.clientId,
      clientType: user.data.clientData.clientType,
    })
  const { data: providersLengthReturnValue, isFetching: isFetchingProviders } =
    useGetProvidersLength({
      patient,
      serviceLines: clientAvailableSLs,
      licenseJurisdiction: stateAbbrev,
      language: patient?.preferredLanguage,
      options: {
        enabled: Boolean(patient && clientAvailableSLs && Boolean(stateAbbrev)),
      },
    })
  const shouldGoToDashboardNext = useMemo(
    () =>
      user.roster.filter(
        (p: Patient) => p?.onboardingStep === ONBOARDING_STEP.insurance
      )?.length === 0 &&
      userSelectedServiceLines?.length > 0 &&
      serviceLinesNoTherapistsInState?.length > 0 &&
      userSelectedServiceLines.every((serviceLine: ServiceLine) =>
        serviceLinesNoTherapistsInState.some(
          (slType: string) => serviceLine.type === slType
        )
      ),
    [userSelectedServiceLines, serviceLinesNoTherapistsInState]
  )

  useEffect(() => {
    if (isFetchingProviders || !userSelectedServiceLines?.length) return

    const selectedServiceLinesWithoutTherapists: string[] =
      userSelectedServiceLines
        .filter(
          (sl: ServiceLine) => providersLengthReturnValue[sl.serviceType] === 0
        )
        .map((sl: ServiceLine) => sl.type)

    setServiceLinesNoTherapistsInState(selectedServiceLinesWithoutTherapists)
  }, [isFetchingProviders, userSelectedServiceLines])

  const toggleServiceLine = (serviceLine: ServiceLine) => {
    if (!userSelectedServiceLines.includes(serviceLine))
      setUserSelectedServiceLines((current) => [...current, serviceLine])
    else
      setUserSelectedServiceLines((current) =>
        current.filter(
          (line: ServiceLine) => line.displayName !== serviceLine.displayName
        )
      )
  }

  const handleNext = (defaultServiceLines = null) => {
    let conditions = patient?.conditions || []
    const carePlans = []
    const serviceLines: ServiceLine[] =
      defaultServiceLines || userSelectedServiceLines

    for (let i = 0; i < serviceLines?.length; i++) {
      const serviceLine = serviceLines[i]

      if (
        !patientCarePlans?.some(
          (cp: CarePlan) => cp.displayName === serviceLine.displayName
        )
      ) {
        carePlans.push({
          serviceType: serviceLine.serviceType,
          language: patient?.preferredLanguage || 'English',
          productId: user.products.find(
            (p: Product) =>
              p.serviceLine.serviceType === serviceLine.serviceType && !p.isIep
          )?.id,
        })

        conditions = [{ id: null, carePlans }]
      }
    }

    if (conditions?.some((c) => c?.carePlans?.some((c) => !c.productId))) {
      addToast('warning', 'No product found for an eligible care plan.')
      return navigate('/dashboard')
    }

    callUpdateProfile(
      { retryConditions: true, patient: { ...patient, conditions }, user },
      {
        onError: () => addToast('error', 'Something went wrong.'),
        onSuccess: (newPatient: Patient) => {
          newPatient.onboardingStep = ONBOARDING_STEP.insurance
          const newUser = cloneDeep(user)
          const patientIdx = newUser.roster.findIndex(
            (p: Patient) => p?.id === patient?.id
          )

          newUser.roster[patientIdx] = newPatient
          flushSync(() => {
            setUser(newUser)

            // repeat onboarding for rest of patients if sign up from email
            if (signUpFromEmail) {
              const potentialPatient = user.roster.find(
                (p) =>
                  !p.state &&
                  !p.gender &&
                  p.onboardingStep !== ONBOARDING_STEP.skipped &&
                  p.onboardingStep !== ONBOARDING_STEP.skippedInErrorDob
              )

              if (potentialPatient) {
                setPatient(potentialPatient)
                return navigate(
                  `/onboarding/build-profile?signUpFromEmail=${signUpFromEmail}&patientId=${potentialPatient.id}`,
                  { replace: true }
                )
              }
            }

            setPatient(newPatient)

            trackMixPanel({
              eventName: MIXPANEL_DATA.eventName,
              properties: {
                ...MIXPANEL_DATA.properties,
                patient: newPatient?.firstName + ' ' + newPatient?.lastName,
              },
            })

            if (shouldGoToDashboardNext) navigate('/dashboard')
            else if (skipAddAnotherPatient) navigate('/insurance')
            else navigate('/onboarding/add-another-person')
          })
        },
      }
    )
  }

  // default selects first line available if there is only one
  useEffect(() => {
    if (isLoadingClientAvailableSLs) return

    if (clientAvailableSLs?.length === 0) {
      addToast('warning', 'No service lines available at this time.')
      navigate('/dashboard')
      return
    }

    if (clientAvailableSLs?.length === 1) handleNext([clientAvailableSLs[0]])

    setServiceLinesForDisplay(clientAvailableSLs)
  }, [isLoadingClientAvailableSLs])

  const isLoading = isFetchingProviders || isLoadingUpdateProfile

  if (
    isLoadingClientAvailableSLs ||
    isFetchingProviders ||
    clientAvailableSLs?.length === 1
  )
    return <GlobalLoader />

  if (!stateAbbrev) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-xl">
      <div className="mx-auto">
        <Stepper
          steps={[
            { name: '1', status: 'complete' },
            { name: '2', status: 'complete' },
            { name: '3', status: 'complete' },
            { name: '4', status: 'current' },
          ]}
        />
      </div>

      <div className="flex flex-col items-center gap-6 text-center">
        <p className="text-base font-semibold sm:text-2xl">
          {forSelf ? (
            'What '
          ) : (
            <>
              For <span className="text-cta-default">{patient.firstName}</span>,{' '}
              what{' '}
            </>
          )}
          area of focus are you looking for?
        </p>
        <p className="text-center text-sm sm:text-base">
          Please select all that apply
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-4">
        {serviceLinesForDisplay.map((serviceLine: ServiceLine) => (
          <button
            key={serviceLine.serviceType}
            onClick={() => toggleServiceLine(serviceLine)}
            disabled={isLoadingUpdateProfile}
            className={`${
              userSelectedServiceLines.includes(serviceLine)
                ? 'border border-cta-default bg-components-paleBlue text-cta-default'
                : 'border border-components-fillBorders bg-components-fillBorders text-text-primary'
            } rounded-xl p-3 text-sm font-semibold sm:rounded-2xl sm:p-4 sm:text-base`}
          >
            {serviceLine.displayName}
          </button>
        ))}
        {!isFetchingProviders &&
          userSelectedServiceLines?.length > 0 &&
          serviceLinesNoTherapistsInState?.length > 0 &&
          serviceLinesNoTherapistsInState.some((slType: string) =>
            userSelectedServiceLines.some(
              (sl: ServiceLine) => slType === sl.type
            )
          ) && (
            <p className="w-full text-center text-xs text-status-error">
              {capitalize(
                `No ${enumeratedElementsFromArray(
                  serviceLinesNoTherapistsInState
                )} therapists found in`
              ) +
                ` ${patient?.state}. We will notify you once they become available!`}
            </p>
          )}
      </div>

      <div className="flex w-full flex-col items-center gap-6 sm:gap-10">
        <div className="flex w-full gap-2 sm:gap-4">
          <button
            type="button"
            className={`w-1/2 ${tertiaryButtonClass}`}
            onClick={() => navigate(-1)}
            disabled={isLoading}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </button>
          <button
            type="submit"
            className={`w-1/2 ${primaryButtonClass}`}
            disabled={
              userSelectedServiceLines?.length === 0 ||
              isFetchingProviders ||
              isLoadingUpdateProfile
            }
            onClick={() => handleNext()}
          >
            {isLoading ? (
              <>
                <RefreshIcon
                  className="loader h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Loading
              </>
            ) : (
              <>
                {shouldGoToDashboardNext ? 'Go to dashboard' : 'Next'}
                <ChevronRightIcon className="h-5 w-5 text-white" />
              </>
            )}
          </button>
        </div>
      </div>

      {!isLoading && (
        <SkipAndComeBackLater
          mixPanelData={{
            ...MIXPANEL_DATA,
            properties: {
              ...MIXPANEL_DATA.properties,
              skipped: true,
            },
          }}
        />
      )}
    </div>
  )
}

export default AreaFocus
