import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, addWeeks, differenceInDays } from 'date-fns'
import { flushSync } from 'react-dom'

import PHONE_ICON from '../../../assets/icons/PhoneIcon.svg'
import ADDRESS_ICON from '../../../assets/icons/AddressIcon.svg'
import CHECK_IN_ICON from '../../../assets/icons/CheckInIcon.svg'
import type { Task } from '../../../types/Dashboard'
import DashboardElement from '../DashboardElement'
import { secondaryButtonClassSmall } from '../../../constants/classConstants'
import { useAuth } from '../../../contexts/AuthProvider'
import useMediaQuery from '../../../hooks/useMediaQuery'
import {
  formatPossessiveNoun,
  getTaskCompletionDeadlineDate,
  newDate,
} from '../../../helpers/generic'
import type { CarePlan, Condition, Patient } from '../../../types/Patient'
import { usePatient } from '../../../contexts/PatientProvider'
import type { Session } from '../../../types/Session'
import { auth } from '../../../config/firebase'
import type { CheckInTask } from '../../../types/Tasks'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'
import { getTaskTypeFromUrl } from '../../../helpers/utils'

const MyTasks = () => {
  const { user } = useAuth()
  const { setPatient } = usePatient()
  const [tasks, setTasks] = useState<Task[]>([])
  const isLargeEnough = useMediaQuery('(min-width: 640px)')
  const navigate = useNavigate()

  useEffect(() => {
    let futureTasks: Task[] = []
    const futureTasksCheckIn: Task[] = []

    user?.roster
      ?.filter((p) => !p.isDisabled)
      .forEach((patient: Patient) => {
        const completeBeforeDate: string = getTaskCompletionDeadlineDate(
          patient,
          auth.currentUser.metadata.creationTime
        )

        // add emergency contact task
        if (!patient.emergencyContact)
          futureTasks.push({
            title: `Add ${
              patient?.relationship?.key === 'myself'
                ? 'your'
                : formatPossessiveNoun(patient.firstName)
            } emergency contact`,
            completeBefore: completeBeforeDate,
            icon: PHONE_ICON,
            url: '/tasks/emergency-contact',
            patient,
          })

        const patientCarePlans: CarePlan[] = patient?.conditions?.find(
          (c: Condition) => !c.isIep
        )?.carePlans

        const isBookingNonSponsored = Boolean(
          patientCarePlans?.find(
            (cp: CarePlan) => cp.bookingNonSponsoredInformationConfirmed
          )
        )

        // add physical address task
        if (
          isBookingNonSponsored &&
          (!patient.streetAddress ||
            !patient.city ||
            !patient.state ||
            !patient.zip)
        ) {
          // first pending COS session (assuming sessions is sorted ascendingly by startTime)
          const pendingNonSponsoredSession: Session = patientCarePlans
            ?.find((cp: CarePlan) => cp.bookingNonSponsoredInformationConfirmed)
            ?.sessions?.find((s: Session) => s.status === 'pending')

          const completeBeforeNonSponsoredSessionDate: Date = newDate(
            new Date(pendingNonSponsoredSession?.startTime),
            patient.timeZone
          )

          futureTasks.push({
            title: `Add ${
              patient?.relationship?.key === 'myself'
                ? 'your'
                : formatPossessiveNoun(patient.firstName)
            } physical address`,
            completeBefore: pendingNonSponsoredSession
              ? completeBeforeNonSponsoredSessionDate.toLocaleDateString()
              : completeBeforeDate,
            icon: ADDRESS_ICON,
            url: '/tasks/physical-address',
            patient,
          })
        }

        // outcome task if patient is allowed to book, is 12 years old or older and has an emergency contact
        if (
          user?.roster?.some((p) => p?.isEligible) &&
          patient.age >= 12 &&
          patient?.emergencyContact?.firstName
        ) {
          let completedBeforeDateTask: string = null
          const outcome: CheckInTask = patient?.checkInTask

          if (outcome) {
            // first time
            if (!outcome.completedAt) {
              const dateOfTheAssessment = patient?.conditions?.flatMap(
                (c) =>
                  c?.carePlans
                    ?.find((cp) => cp.displayName === 'Mental Health Therapy')
                    ?.sessions?.find((s) => s.status === 'pending')?.startTime
              )

              if (dateOfTheAssessment?.length === 0 || !dateOfTheAssessment[0])
                completedBeforeDateTask = addWeeks(
                  new Date(auth.currentUser.metadata.creationTime),
                  1
                ).toLocaleDateString()
              else
                completedBeforeDateTask = new Date(
                  dateOfTheAssessment[0]
                ).toLocaleDateString()
            }
            // recurring
            else if (
              differenceInDays(new Date(), new Date(outcome.completedAt)) >= 14
            )
              completedBeforeDateTask = addWeeks(
                new Date(outcome.completedAt),
                3
              ).toLocaleDateString()

            if (completedBeforeDateTask)
              futureTasksCheckIn.push({
                completeBefore: completedBeforeDateTask,
                icon: CHECK_IN_ICON,
                url: `/tasks/check-in/${patient.id}`,
                title:
                  "Let's do a quick check-in" +
                  (patient?.relationship?.key !== 'myself'
                    ? ` for ${patient.firstName}!`
                    : '!'),
                patient,
              })
          }
        }
      })

    futureTasks = futureTasks.concat(futureTasksCheckIn)

    const userTasksFromLocalStorage = JSON.parse(
      localStorage.getItem('DOTCOM_USER_TASKS')
    )

    if (userTasksFromLocalStorage?.[user.data.id])
      futureTasks = futureTasks.concat(
        Object.values(userTasksFromLocalStorage[user.data.id] as Task[])
      )

    setTasks(futureTasks)
  }, [user])

  const goToTask = (
    patient: Patient | null,
    taskUrl: string,
    isOverdue: boolean
  ) => {
    if (patient) {
      trackMixPanel({
        eventName: MIXPANEL_EVENT.TASK_VIEWED,
        properties: {
          type: getTaskTypeFromUrl(taskUrl),
          overdue: isOverdue,
          patient: patient?.firstName + ' ' + patient?.lastName,
        },
      })
    }
    flushSync(() => {
      setPatient({ ...patient, onboardingStep: null })
      navigate(taskUrl)
    })
  }

  if (!tasks?.length) return null

  return (
    <DashboardElement title="My Tasks" noGap hidden={!Boolean(tasks?.length)}>
      <p className="text-lg font-semibold text-text-secondary xs:text-base">
        You have{' '}
        <span className="text-primaryAccents-orange">{tasks?.length}</span> task
        {tasks?.length > 1 ? 's' : ''} to complete:
      </p>
      {React.Children.toArray(
        tasks.map((t: Task) => (
          <div
            role="presentation"
            onClick={(e) => {
              isLargeEnough ? e.preventDefault() : navigate(t.url)
            }}
            className="flex cursor-default items-center justify-between rounded-2xl border border-components-fields py-4 px-6 xs:gap-2 xs:rounded-lg xs:py-4 xs:px-4"
          >
            <div className="flex w-full items-center gap-2">
              <img
                src={t.icon}
                alt="task-icon"
                className="h-auto w-auto xs:h-6 xs:w-6"
              />
              <div className="flex w-full items-center gap-2 xs:flex-col xs:items-start xs:gap-0">
                <p className="w-full max-w-sm text-left text-base font-semibold xs:text-sm">
                  {t.title}
                </p>
                {new Date(t.completeBefore) <
                new Date(format(new Date(), 'M/d/yyyy')) ? (
                  <p className="text-left text-base font-semibold text-primaryAccents-orange xs:text-sm">
                    Overdue
                  </p>
                ) : (
                  <p className="text-left text-base font-normal text-text-secondary xs:text-sm">
                    Complete before {t.completeBefore}
                  </p>
                )}
              </div>
            </div>

            <button
              type="button"
              onClick={() =>
                goToTask(
                  t.patient,
                  t.url,
                  new Date(t.completeBefore) <
                    new Date(format(new Date(), 'M/d/yyyy'))
                )
              }
              className={`${secondaryButtonClassSmall} flex-shrink-0 xs:hidden`}
            >
              View task
            </button>
          </div>
        ))
      )}
    </DashboardElement>
  )
}

export default MyTasks
