import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon, RefreshIcon } from '@heroicons/react/solid'
import { CalculatorIcon, ExclamationIcon } from '@heroicons/react/outline'
import { formatInTimeZone } from 'date-fns-tz'
import { format, differenceInMinutes } from 'date-fns'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import CALENDAR from '../../assets/icons/CalendarGray.svg'
import THERAPY from '../../assets/icons/therapy.svg'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import {
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import type { ServiceLine } from '../../types/ServiceLine'
import type { ProviderBookingLocationState } from '../../types/Booking'
import { useSaveAppointment } from '../../mutations/booking/SaveAppointment'
import { useAuth } from '../../contexts/AuthProvider'
import { useToastContext } from '../../contexts/ToastContext'
import { LICENSE_CREDENTIAL } from '../../constants/values'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import { toCamelCase } from '../../helpers/serializers'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'
import type { Session } from '../../types/Session'
import { toSession } from '../../helpers/transformers'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import { usePatient } from '../../contexts/PatientProvider'
import useIsSelfPay from '../../hooks/useIsSelfPay'
import EditCreditCardModal from '../../components/Modals/EditCreditCardModal'
import ChangedTherapistFeedbackModal from '../../components/Modals/ChangedTherapistFeedbackModal'
import { getCardBrandIcon } from '../../helpers/utils'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import useIsPatientPayCOS from '../../hooks/useIsPatientPayCOS'
import { useProviderContext } from '../../contexts/ProviderContext'
import CrisisMessaging from '../../components/CrisisMessaging'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { useGetPrices } from '../../queries/payments/GetPrices'
import useIsClientPayCOS from '../../hooks/useIsClientPayCOS'

const ProviderConfirmation: React.FC = () => {
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const [openChangedTherapistModal, setOpenChangedTherapistModal] =
    useState<boolean>(false)
  const navigate = useNavigate()
  const { providerId } = useParams()
  const location = useLocation()
  const locationState = location.state as ProviderBookingLocationState
  const { user, setUser } = useAuth()
  const paymentMethod = user.paymentMethod
  const { patient, setPatient } = usePatient()
  const addToast = useToastContext()
  const [therapistNotes, setTherapistNotes] = useState<string>('')
  const [isOpenEditCreditCardModal, setIsOpenEditCreditCardModal] =
    useState<boolean>(false)
  const { mutate: callSaveAppointment, isLoading: isValidatingReservation } =
    useSaveAppointment()
  const { mutate: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const { data: prices, isLoading: isLoadingPrices } = useGetPrices()
  const isAssessment: boolean = useMemo(
    () => getIsAssessmentStatus(patient, currentServiceLine?.displayName),
    [patient, currentServiceLine]
  )
  const timeSlotRange = `${formatInTimeZone(
    locationState.selectedTimeSlot.startOriginal,
    patient.timeZone,
    'hh:mm aa'
  )} - ${formatInTimeZone(
    locationState.selectedTimeSlot.endOriginal,
    patient.timeZone,
    'hh:mm aa'
  )}`
  const patientCarePlans: CarePlan[] = patient?.conditions?.find(
    (c: Condition) => !c.isIep
  )?.carePlans
  const currentCarePlan = patientCarePlans?.find(
    (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
  )
  const currentCarePlanProviderId: string = currentCarePlan?.providerId
  const currentCarePlanProvider = currentCarePlan?.sessions?.find(
    (s: Session) => s.therapist.id === currentCarePlanProviderId
  )?.therapist
  const { wantDifferentTherapist, showTherapistModal } = useProviderContext()
  const changedProvider: boolean =
    currentCarePlanProviderId && providerId !== currentCarePlanProviderId

  const isSelfPay: boolean = useIsSelfPay()
  const isPatientPayCOS = useIsPatientPayCOS()
  const isClientPayCOS = useIsClientPayCOS()

  const durationInMinutes: number = differenceInMinutes(
    new Date(locationState.selectedTimeSlot.endOriginal),
    new Date(locationState.selectedTimeSlot.startOriginal)
  )

  const saveAppointment = () => {
    const currentPatient = cloneDeep(patient)
    const currentPatientCarePlans: CarePlan[] =
      currentPatient?.conditions?.find(
        (c: Condition) => !c.isIep && c.carePlans?.length
      )?.carePlans
    let currentCarePlanIdx = -1

    currentCarePlanIdx = currentPatientCarePlans.findIndex(
      (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
    )

    if (currentCarePlanIdx === -1) {
      addToast(
        'warning',
        'Something went wrong, please contact support. Care Plan not found.'
      )
      return
    }

    currentPatientCarePlans[currentCarePlanIdx].providerId = providerId
    currentPatientCarePlans[currentCarePlanIdx].notes = therapistNotes

    callUpdateProfile(
      {
        patient: currentPatient,
        user,
      },
      {
        onError: () => addToast('error', 'Something went wrong.'),
        onSuccess: (resultPatient: Patient) => {
          const newPatient = {
            ...resultPatient,
            insurance: currentPatient.insurance,
          }
          const newPatientCondition: Condition = newPatient?.conditions?.find(
            (c: Condition) => !c.isIep && c.carePlans?.length
          )
          const newPatientCarePlans: CarePlan[] = newPatientCondition?.carePlans
          currentCarePlanIdx = newPatientCarePlans.findIndex(
            (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
          )

          if (currentCarePlanIdx === -1) {
            addToast(
              'warning',
              'Something went wrong, please contact support. Care Plan not found.'
            )
            return
          }

          callSaveAppointment(
            {
              providerId,
              clientId: user.data.clientId,
              patient: newPatient,
              startTime: locationState.selectedTimeSlot.startOriginal,
              endTime: locationState.selectedTimeSlot.endOriginal,
            },
            {
              onSuccess: (data: any) => {
                if (!data.id) {
                  addToast(
                    'warning',
                    'Something went wrong, please contact support'
                  )
                  return
                }
                const newUser = cloneDeep(user)
                const newAppointment = toCamelCase(data)
                const patientCarePlan: CarePlan = cloneDeep(
                  newPatientCarePlans[currentCarePlanIdx]
                )
                const newSession: Session = toSession(
                  newAppointment,
                  newPatient,
                  patientCarePlan.id,
                  newPatientCondition.id,
                  locationState.provider
                )

                patientCarePlan.remainingSessions =
                  typeof patientCarePlan.remainingSessions === 'number'
                    ? patientCarePlan.remainingSessions -
                      (durationInMinutes === 60 ? 2 : 1)
                    : null
                patientCarePlan.bookingNonSponsoredInformationConfirmed =
                  patientCarePlan.remainingSessions < 0 &&
                  Boolean(currentCarePlan.allowedSessions)

                if (changedProvider) {
                  const newSessions = patientCarePlan.sessions.reduce(
                    (acc: Session[], s) => {
                      if (
                        s.therapist.id === currentCarePlanProviderId &&
                        s.status === 'pending'
                      )
                        return acc
                      else return [...acc, s]
                    },
                    []
                  )
                  patientCarePlan.sessions = newSessions
                }
                patientCarePlan.sessions.push(newSession)
                newPatientCarePlans[currentCarePlanIdx] = patientCarePlan
                const patientIndex = user.roster.findIndex(
                  (p: Patient) => p.id === newPatient.id
                )
                newUser.roster[patientIndex] = newPatient
                flushSync(() => {
                  const eventSource = currentPatient.onboardingStep
                    ? 'Onboarding'
                    : 'Booking'

                  trackMixPanel({
                    eventName: MIXPANEL_EVENT.THERAPIST_UPDATED,
                    properties: {
                      source: eventSource,
                      therapistId: providerId,
                      patient: patient?.firstName + ' ' + patient?.lastName,
                      update: changedProvider
                        ? 'Updated Therapist'
                        : 'New Therapist',
                    },
                  })
                  trackMixPanel({
                    eventName: MIXPANEL_EVENT.SESSION_BOOKED,
                    properties: {
                      source: eventSource,
                      therapistId: providerId,
                      sessionId: newSession.id,
                      patient: patient?.firstName + ' ' + patient?.lastName,
                    },
                  })
                  setUser(newUser)
                  setPatient({ ...newPatient, onboardingStep: null })
                  if (
                    changedProvider &&
                    wantDifferentTherapist &&
                    showTherapistModal
                  ) {
                    setOpenChangedTherapistModal(true)
                    addToast('success', 'Session booked successfully!')
                  } else
                    navigate(
                      `/booking/${currentServiceLine.url}/providers/${providerId}/success`,
                      {
                        state: {
                          newAppointment,
                          provider: locationState.provider,
                        },
                      }
                    )
                })
              },
              onError: () => addToast('error', 'Something went wrong.'),
            }
          )
        },
      }
    )
  }

  useEffect(() => {
    if (!locationState?.provider) return

    // TODO - Add Mixpanel log
    // setExtraLog(
    //   `Booking / ${currentServiceLine.displayName} / Appointment Confirmation Screen / ${locationState.provider.preferredName}`
    // )
  }, [])

  if (
    !locationState?.provider ||
    !locationState?.selectedDate ||
    !locationState?.selectedTimeSlot
  ) {
    navigate('/404', { replace: true })
    return
  }

  if (!patient) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-lg items-center text-center">
      <div className="flex w-full max-w-md flex-col gap-6 sm:gap-10">
        <div className="flex flex-col gap-2 sm:gap-6">
          <h2 className="text-base font-semibold sm:text-2xl sm:font-medium">
            Let's make it official
          </h2>
          <p className="text-sm font-normal sm:text-base">
            Review and confirm your booking information :)
            {isPatientPayCOS && isSelfPay && (
              <>
                <br />
                Don't worry, you will not be charged until 24 hours before the
                session!
              </>
            )}
          </p>
        </div>
        <div className="flex flex-col gap-4 rounded-2xl border border-components-fields bg-white p-4 text-left sm:gap-8 sm:py-4 sm:px-10">
          {/* Date */}
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center gap-2">
              <img src={CALENDAR} alt="Calendar" className="h-6 w-6" />
              <p className="text-sm font-semibold sm:text-base">
                {format(locationState.selectedDate, 'EEEE, LLLL d, yyyy')}
              </p>
            </div>
            <p className="ml-8 text-sm font-normal sm:text-base">
              {`${timeSlotRange} ${formatInTimeZone(
                new Date(),
                patient.timeZone,
                'zzz'
              )}`}
            </p>
          </div>
          {/* Therapist & Session info */}
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <img src={THERAPY} alt="Support" className="h-6 w-6" />
              <p className="text-sm font-semibold sm:text-base">
                {currentServiceLine.displayName}{' '}
                {isAssessment ? 'Assessment' : 'Session'}{' '}
                {`- ${durationInMinutes} Min`}
              </p>
            </div>
            <div className="ml-8 flex flex-row items-center gap-6">
              <img
                src={locationState.provider.src}
                alt="Therapist"
                className="h-11 w-11 rounded-full border-4 border-components-paleBlue object-cover"
              />
              <div className="flex flex-col text-text-secondary">
                <p className="text-xs font-semibold sm:text-base">
                  {locationState.provider.preferredName}
                </p>
                <p className="text-label text-left text-xs sm:text-sm">
                  {locationState?.provider?.licenseCredential ||
                    LICENSE_CREDENTIAL[
                      SERVICE_LINES_ARRAY.find(
                        (line: ServiceLine) =>
                          line.displayName ===
                          locationState.provider.serviceLine
                      ).serviceType
                    ]}
                </p>
              </div>
            </div>
          </div>
          {/* Pricing for self pay */}
          {isPatientPayCOS && isSelfPay && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <CalculatorIcon className="h-6 w-6 text-text-label" />
                <p className="text-sm font-semibold sm:text-base">
                  $
                  {isLoadingPrices
                    ? '-'
                    : (durationInMinutes / 30) * prices[30]}
                </p>
              </div>
              {Boolean(paymentMethod) && (
                <div className="ml-8 flex flex-row items-center gap-1">
                  <img
                    src={getCardBrandIcon(paymentMethod.cardBrand)}
                    alt="card"
                    className="h-6 w-9"
                  />
                  <p className="text-xs font-normal text-text-secondary sm:text-sm">
                    -{paymentMethod.cardLastDigits}
                  </p>
                </div>
              )}
              <button
                className="ml-8 self-start text-sm text-text-secondary underline"
                onClick={() => setIsOpenEditCreditCardModal(true)}
              >
                Update card info
              </button>
              <EditCreditCardModal
                open={isOpenEditCreditCardModal}
                setOpen={setIsOpenEditCreditCardModal}
              />
            </div>
          )}
          {/* Pricing for insurance use */}
          {isPatientPayCOS && !isSelfPay && (
            <div className="flex flex-row items-center gap-2">
              <CalculatorIcon className="h-6 w-6 text-text-label" />
              <p className="text-sm font-semibold sm:text-base">
                We will bill your insurance
              </p>
            </div>
          )}
          {/* Pricing for client pay */}
          {isClientPayCOS && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <CalculatorIcon className="h-6 w-6 text-text-label" />
                <p className="text-sm font-semibold sm:text-base">
                  Covered by your sponsor
                </p>
              </div>
            </div>
          )}
        </div>
        {isAssessment && (
          <form className="flex flex-col gap-1 text-left">
            <label
              htmlFor="therapistNotes"
              className="text-sm font-semibold sm:text-base"
            >
              Notes for the therapist (optional)
            </label>
            <textarea
              value={therapistNotes}
              onChange={(e) => setTherapistNotes(e.currentTarget.value)}
              className={inputValidClass + 'flex-1'}
              placeholder="Enter"
            />
          </form>
        )}
      </div>
      <CrisisMessaging />
      {isPatientPayCOS &&
        patient?.insurance?.insurance?.insuranceType !== 'Medicaid' && (
          <p className="text-xs">
            Please note that you will be responsible for the full price of the
            session for no-shows, modifications, and cancellations made within
            24 hours before the session.
          </p>
        )}
      {changedProvider && (
        <div className="flex items-start justify-center rounded-lg bg-background-offwhite py-4 px-2">
          <ExclamationIcon className="w-10 text-status-error" />
          <p className="text-sm font-semibold">
            Once confirmed, any future sessions you had booked with{' '}
            {currentCarePlanProvider?.preferredName} (your original therapist)
            will be cancelled.
          </p>
        </div>
      )}
      <div className="flex w-full flex-col gap-4 sm:gap-12">
        <div className="flex flex-row gap-2 sm:gap-4">
          <button
            disabled={isValidatingReservation || isLoadingUpdateProfile}
            onClick={() => navigate(-1)}
            className={`${tertiaryButtonClass} w-full`}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            <p>Back</p>
          </button>
          <button
            disabled={isValidatingReservation || isLoadingUpdateProfile}
            onClick={saveAppointment}
            className={`${primaryButtonClass} w-full`}
          >
            {isValidatingReservation || isLoadingUpdateProfile ? (
              <>
                <RefreshIcon
                  className="loader h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Loading
              </>
            ) : (
              <p>Reserve my spot!</p>
            )}
          </button>
        </div>
        {!isLoadingUpdateProfile && !isValidatingReservation && (
          <SkipAndComeBackLater />
        )}
        <ChangedTherapistFeedbackModal
          open={openChangedTherapistModal}
          setOpen={setOpenChangedTherapistModal}
          currentCarePlanProviderId={currentCarePlanProviderId}
        />
      </div>
    </div>
  )
}

export default ProviderConfirmation
